.Footer {
    background: white;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.YD {
    line-height: 96px;
    font-size: 12px;
    color: #3b5998;
    cursor: pointer;
}

.YD:hover {
    text-decoration: underline;
}

.FooterText {
    font-size: 20px;
    line-height: 96px;
}

@media only screen and (max-width: 1200px) {
    .FooterText {
        font-size: 14px;
    }
}

@media only screen and (max-width: 992px) {
    .FooterText {
        font-size: 12px;
    }
}