.Modal {
    max-width: 890px;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 20px;
    background: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    z-index: 100;
}

.Modal-close {
    width: 50px;
    height: 50px;
    position: absolute;
    right: -25px;
    top: -25px;
    border-radius: 50%;
    border: none;
    background: #171c30;
    color: white;
    cursor: pointer;
}

.Modal-close:after {
    content: "X";
    font-weight: bold;
    font-size: 30px;
}

@media only screen and (max-width: 768px) {
    .Modal {
        top: 0;
        overflow: scroll;
        height: 100%;
        top: 0;
    }
    .Modal-close {
        right: 0;
        top: 0;
    }
}