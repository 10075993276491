 .Section {
     margin-top: 6rem;
     margin-bottom: 6rem;
 }

 .SectionImage {
     height: 600px;
 }

 .SectionImage img {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
 }

 .SectionItem {
     text-align: center;
     height: 120px;
     border-top: 1px solid #CCC;
     border-right: 1px solid #CCC;
 }

 .SectionText {
     cursor: default;
     font-size: 14px;
     font-family: 'Montserrat', sans-serif;
 }

 .SectionItem.last-child {
     border-bottom: 1px solid #CCC;
 }

 .SectionItem svg {
     font-size: 50px;
     color: #41902d;
 }

 @media only screen and (max-width: 1200px) {
     .SectionItem svg {
         font-size: 45px;
     }
     .SectionText {
         font-size: 13px;
     }
 }

 @media only screen and (max-width: 992px) {
     .SectionItem svg {
         font-size: 45px;
     }
     .SectionText {
         font-size: 12px;
     }
 }