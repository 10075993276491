body {
    background: #F2F4F1;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}

*::selection {
    background: #308619;
    color: #69dc69;
    text-shadow: 1px 1px 3px #000;
}

/* Firefox */

*::-moz-selection {
    background: #308619;
    color: #69dc69;
    text-shadow: 1px 1px 3px #000;
}

h1, h2, h3, h4, h5 {
    font-family: 'SprayPaint';
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

span.required {
    color: red;
    margin-right: 2px
}

.formInputError {
    border: 1px solid #f32e31!important;
}

.page {
    position: absolute;
    width: 100%;
    height: 100%;
}

.page-enter {
    opacity: 0;
    transform: scale(1.1);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}