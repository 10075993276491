@font-face {
    font-family: 'SprayPaint';
    src: url('spraypaint.woff2') format('woff2'), url('spraypaint.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Cabin Sketch';
    font-style: normal;
    font-weight: normal;
    src: url('./cabin-sketch.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('./montserrat.woff2') format('woff2');
}