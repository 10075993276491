.ChangeLanguage {
    margin-right: 1rem;
    font-size: 0.8rem;
    text-align: right;
    color: #001a7e;
}

.ChangeLanguageTop {
    line-height: 95px
}

.ChangeLanguageFix {
    line-height: 65px
}

.ChangeLanguage span {
    cursor: pointer;
    text-decoration: underline;
}