.Loading {
    position: fixed;
    width: 100%;
    margin: 0;
    background: #d7ffe2b5;
    padding: 7rem;
    text-align: center;
    left: 0;
    height: 100%;
    top: 0;
    z-index: 10;
}

.Loading .fa-spinner {
    color: #2f8519;
    font-size: 70px;
}