.HomeSlide {
    background-image: url("./../../assets/images/home.webp");
    min-height: 620px;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
}

.HomeSlide img {
    padding: 10px;
    max-width: 350px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .HomeSlide {
        background-attachment: scroll;
    }
}