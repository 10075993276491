.SocialMedia {
    padding: 20px 5px;
}

.SocialMedia svg {
    font-size: 30px;
}

.SocialMediaBtnLink {
    border-radius: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    transition: all 0.6s
}

.SocialMediaW {
    background: #4cee65;
    line-height: 50px;
}

.SocialMediaF {
    background: #3b5998;
    line-height: 40px;
}

.SocialMediaI {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
    line-height: 50px;
}

.SocialMediaF svg {
    font-size: 20px!important;
}

.SocialMediaBtnLink:hover {
    box-shadow: 0 0 10px #444;
}