.ViewProduct {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.ViewProduct h2 {
  font-family: "Montserrat", sans-serif;
}

.ViewProductSubtitle {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

.ViewProductText {
  font-size: 16px;
  text-align: justify;
}
