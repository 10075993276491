.Contact {
    margin-top: 20px;
}

.ContactSubtitle {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
}

.ContactPage {
    background: #ffffffc7;
    padding: 20px;
    border-radius: 5px;
}